@import url("https://fonts.googleapis.com/css?family=Open+Sans:light,regular,bold,italic&subset=latin,latin-ext");
html {
  font-size: 14px;
}

body {
  font-family: "Open Sans", Helvetica, sans-serif !important;
}

.header {
  line-height: 1.4rem;
  font-size: 1.2rem;
  font-weight: 400;
  border-bottom: 1px solid #aaa;
  margin-top: 1.3rem !important;
  text-align: left;
  padding-bottom: 6px;
}

h4 {
  font-size: 1.2rem !important;
  margin-bottom: 0.1rem !important;
}

.blue {
  color: #478fca !important;
}

.fa {
  margin-right: 4px;
}

.App {
  text-align: center;
  min-height: 100vh;
  margin-bottom: -100px;
  padding-bottom: 100px;
  position: relative;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.passport {
  line-height: 1.4rem;
  font-size: 1.2rem;
  font-weight: 400;
  border-bottom: 1px solid #aaa;
  margin-top: 1.3rem !important;
  text-align: left;
  padding-bottom: 6px;
  color: #478fca;
}

.DateRangePicker {
  margin: 10px;
}
.DateRangePicker input {
  padding: 4px;
  font-weight: 400;
  font-size: 14px;
}

.DateInput {
  margin: 10px;
}
.DateInput input {
  padding: 4px;
  font-weight: 400;
  font-size: 14px;
}

.rbc-event {
  background-color: transparent !important;
}

.rbc-month-row {
  overflow: visible !important;
}

.trip_event {
  border-radius: 4px;
  padding: 2px 4px;
  color: white;
  background-color: chocolate;
}

.ass_event {
  border-radius: 4px;
  padding: 2px 4px;
  color: lightgoldenrodyellow;
  background-color: darkseagreen;
}

.work_permit_trip {
  border-radius: 4px;
  padding: 2px 4px;
  color: black;
  background-color: lightgrey;
}

form#tripEditorForm .SingleDatePicker {
  margin-top: 12px;
}

form#tripEditorForm .btn {
  margin: 12px;
}

.trip-box {
  border: darkgray thin solid;
}
.trip-box h4 {
  border-bottom: black thin solid;
  margin-bottom: 0.5rem !important;
}

.btn {
  margin: 4px 6px;
}

.slide-pane__header {
  visibility: hidden;
  height: 0;
  display: none !important;
}

.slide-pane__content::after {
  content: "";
  background-image: url(../images/horiz.png);
  opacity: 0.3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

.DateInput_input {
  text-align: center;
}

.dropdown-toggle {
  background: transparent !important;
  border-radius: 10px !important;
}
.dropdown-toggle span {
  color: #333 !important;
}

.dropdown-menu.show {
  left: auto !important;
  right: 10px !important;
  top: 40px !important;
  transform: none !important;
}

.btn-primary {
  background-color: #083d5d !important;
}

.btn-secondary {
  background-color: #083d5d !important;
}

.alert-info {
  color: #083d5d !important;
  font-family: inherit !important;
}

.alert {
  font-family: inherit !important;
}

.btn-admin {
  background-color: #a88d11 !important;
  color: white;
}

.blue {
  color: #083d5d !important;
}

p {
  margin-bottom: 0 !important;
}

.loading-thing {
  color: #083d5d !important;
}

.widget-box {
  margin-bottom: 20px !important;
}

.hidden {
  display: none;
}

.row-no-padding [class*="col-"] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media (max-width: 687px) {
  .navbar > img {
    height: 30px;
    width: auto !important;
  }
  .welcome-header img {
    display: none;
  }
  .nav-tiles-container {
    justify-content: center;
  }
  .country-page-index .d-flex {
    justify-content: space-around;
  }
  .kb-search > .ml-3 {
    margin-left: 0 !important;
  }
}

.tab-panel {
  border-top: hidden !important;
  text-align: left;
}

.react-tabs__tab-list {
  margin-bottom: 0 !important;
  text-align: left;
}

.react-tabs__tab-panel--selected {
  padding: 20px;
  border: solid thin #ccc;
  border-top: none;
}

.passport-expiry {
  color: slategray;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc !important;
}

.btn-default.active {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.toggle.btn {
  margin-top: 6px;
  margin-left: 0px;
}

.modal {
  background-color: rgba(255, 255, 255, 0.8);
}

.widget-main {
  padding: 12px;
}

.widget-box {
  padding: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  margin: 3px 0;
  border: 1px solid #ccc;
}

.widget-header {
  position: relative;
  min-height: 38px;
  background: #f7f7f7;
  background-color: #f8f8f8;
  background-image: -moz-linear-gradient(top, #ffffff, #eeeeee);
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(#ffffff),
    to(#eeeeee)
  );
  background-image: -webkit-linear-gradient(top, #ffffff, #eeeeee);
  background-image: -o-linear-gradient(top, #ffffff, #eeeeee);
  background-image: linear-gradient(to bottom, #ffffff, #eeeeee);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffeeeeee', GradientType=0);
  color: #083d5d;
  border-bottom: 1px solid #ccc;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.widget-toolbox {
  background-color: #eee;
  text-align: right;
}

.expando-box {
  padding: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  margin: 3px 0;
  border: 1px solid #ccc;
}

.expando-header {
  position: relative;
  min-height: 32px;
  color: #083d5d;
  font-weight: normal;
  background-color: #f9f9f9;
  border-bottom: 1px solid #f9f9f9;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expando-main {
  text-align: left;
  padding: 8px;
}

.expando-toolbox {
  background-color: #eee;
  text-align: right;
}

.activity-form .row {
  margin-top: 8px;
}

.side-nav {
  background: #d3d3d3;
  border-radius: 0 6px 0 0;
  border: thin darkgray solid;
  color: white;
  font-size: 1rem;
  font-weight: 400;
  margin-top: 23px;
  min-height: calc(100vh - 79px);
  text-align: left;
  width: 260px;
}
.side-nav a,
.side-nav .nav-box {
  color: inherit;
  cursor: pointer;
}
.side-nav a:hover,
.side-nav .nav-box:hover {
  color: white;
}
.side-nav a:hover,
.side-nav a:hover *,
.side-nav .nav-box:hover,
.side-nav .nav-box:hover * {
  text-decoration: underline;
}
.side-nav #close-menu {
  background-color: #54565b;
  border-bottom: 1px solid #cccccc;
  height: 3.5em;
  padding-top: 0.5em;
}
.side-nav .nav-icon {
  display: inline-block;
  font-size: 1em;
  height: 0.75em;
  vertical-align: middle;
  width: auto;
}
.side-nav .nav-icon .fa {
  margin-right: 0.5em;
  vertical-align: top;
}
.side-nav .nav-icon img {
  display: inline-block;
  height: 1em;
  vertical-align: top;
}
.side-nav > * > div {
  border-bottom: 1px solid #cccccc;
}
.side-nav :not(#close-menu) > div {
  padding: 1em;
}
.side-nav.is-viewing-tab > div > :not(#close-menu) div {
  display: none;
}
.side-nav .nav-text {
  color: #333;
}
.side-nav .nav-text img {
  display: inline-block;
  height: 1em;
  margin: 0.25em 0.5em 0 0;
  vertical-align: top;
}

.side-nav-closed {
  text-align: center;
  width: 50px;
}
.side-nav-closed #close-menu .fa {
  vertical-align: -0.5em;
}
.side-nav-closed > :not(#close-menu) {
  display: block;
  position: relative;
}
.side-nav-closed > :not(#close-menu):not(:hover) .nav-text {
  display: none;
}
.side-nav-closed > :not(#close-menu):hover .nav-text {
  background: white;
  border-radius: 0.25em;
  color: #333;
  left: calc(100% + 1em);
  padding: 0.5em 1em;
  position: absolute;
  top: 0.5em;
  width: auto;
}
.side-nav-closed > :not(#close-menu) .nav-icon {
  display: block;
  font-size: 1.5em;
  padding: 0;
}
.side-nav-closed > :not(#close-menu) .nav-icon .fa {
  margin: 0;
  vertical-align: 0.5em;
}

label {
  text-align: right;
  vertical-align: middle !important;
  font-weight: bolder;
}

.activity-label {
  text-align: left;
  font-weight: normal;
}

.form-group {
  align-items: center;
  margin-bottom: 0.6rem !important;
}

.picker-field {
  padding: 6px 0 !important;
  vertical-align: middle;
}

.report-domain-picker {
  padding: 10px;
}

.ckeditor-container {
  width: 95%;
  margin: 10px auto;
}

.text-editor-container {
  width: 100%;
}

.rdw-editor-toolbar,
.rdw-editor-main {
  width: 95%;
  margin: 10px auto;
}

.rdw-editor-main {
  border: thin darkgrey solid;
  padding: 10px;
}

.text-editor {
  width: 95%;
  margin: 10px auto;
}

.Select-control {
  min-width: 200px;
}

.PageForm .row.mt-2 [title^="P"]:hover,
.PageForm .row.mt-2 [title^="O"]:hover {
  cursor: help;
  text-decoration: underline;
}

.rbt-input-wrapper {
  display: flex;
}

.rbt-token {
  display: flex;
  padding: 2px;
  margin-left: 5px;
  background-color: #3b8ede;
}

#assessment-form-upper #assessment-form-upper-right {
  background-color: #f0f0f0;
  margin: 5px;
  min-width: 200px;
  border: 1px solid #ccc;
}

#assessment-form-upper #assessment-form-upper-left {
  flex-grow: 1;
  display: flex;
  min-width: 200px;
  flex-direction: column;
}

small.assessment-notes {
  display: inline-flex;
  justify-content: space-evenly;
}

.assessment-notes ul {
  list-style-position: inside;
}

.assessment-notes ul li {
  text-align: left;
  margin-top: 5px;
}

#screening-questions {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  max-width: 500px;
}

@media only screen and (max-width: 1860px) {
  #screening-questions {
    max-width: 100%;
  }
}

@media only screen and (max-width: 687px) {
  #assessment-form-upper-right {
    display: none;
  }
}

#activities {
  width: 100%;
  margin-left: 30px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

#assessment-form-buttons {
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
}

.assessment-view h2 {
  font-size: 1.3rem;
  font-weight: bold;
}

.assessment-view h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 10px 0 0 0;
}

.assessment-view h4 {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 12px 0 !important;
}

.assessment-view h6 {
  font-weight: bold;
  margin-top: 12px;
}

.assessment-view .info-box,
.assessment-view .warning-box {
  border: none;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #bbb;
  padding: 12px 20px;
  margin: 5px 0 20px 0;
  text-align: left;
}
.assessment-view .info-box :first-child,
.assessment-view .warning-box :first-child {
  margin-top: 0px;
}
.assessment-view .info-box h3,
.assessment-view .warning-box h3 {
  color: #007bff;
}

.assessment-view .warning-box h3 {
  color: #a7173e;
}

.assessment-view .override-notice {
  color: darkred;
  font-weight: bold;
}

.assessment-view .info-output {
  margin-top: 12px;
  font-size: 1rem;
}

.PhoneInput {
  display: flex;
  margin-bottom: 10px;
}

.PhoneInputCountryIconImg {
  width: 33px;
  margin-left: -7px;
  padding-top: 3px;
  margin-right: 10px;
}

.PhoneInputCountrySelect {
  display: none;
}

.screening-radio-group {
  padding-left: 15px;
}

.screening-number-input {
  padding-right: 15px;
  padding-left: 15px;
}

#passport-outcomes > div:first-child {
  margin-top: 20px;
}

.DayPickerNavigation_svg__horizontal {
  pointer-events: none;
}

.d-flex-override {
  flex-wrap: wrap;
}

.col-sm-4 {
  min-width: 120px;
  max-width: 150px;
  width: unset !important;
}

.destination-col-sm-4 {
  flex-wrap: nowrap !important;
}

@media only screen and (max-width: 430px) {
  .destination-col-sm-4 {
    flex-wrap: wrap !important;
  }
}

.overall-outcome h3 + h3 {
  color: #007bff;
  margin-bottom: 1rem;
}

#outcomeFactors {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #bbb;
}

.delete-assessment-icon {
  color: #ccc;
}
.delete-assessment-icon:hover {
  color: red;
}

.welcome-header {
  justify-content: space-between;
  margin: auto;
  max-width: calc(800px + 4em - 1em);
  text-align: left;
}

.nav-tiles-container {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  max-width: calc(600px + 3em);
  padding-top: 6em;
}
.nav-tiles-container.large {
  max-width: calc(800px + 4em);
  padding-top: 0;
}
.nav-tiles-container .nav-tile {
  cursor: pointer;
  display: inline-block;
  margin: 0.5em;
  transition: 0.3s transform;
}
.nav-tiles-container .nav-tile:hover {
  transform: scale(1.05);
}
.nav-tiles-container .nav-tile > * {
  background-color: #083d5d;
  background-position: center 30%;
  background-repeat: no-repeat;
  background-size: 20%;
  border-radius: 5%;
  box-shadow: #bbb 2px 2px 2px;
  height: 200px;
  position: relative;
  transition: 0.3s transform;
  width: 200px;
}
.nav-tiles-container .nav-tile > * p {
  bottom: 5%;
  color: white;
  font-size: 1.8em;
  padding: 0 0.5em;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 100%;
}
.nav-tiles-container .nav-tile > * div {
  color: white;
  display: none;
  padding: 1em 0.5em;
}
.nav-tiles-container .nav-tile > * a {
  transition: box-shadow 0.3s;
}
.nav-tiles-container .nav-tile > * a,
.nav-tiles-container .nav-tile > * a:hover {
  border: solid 1px white;
  color: white !important;
  display: inline-block;
  padding: 4px;
  text-decoration: none !important;
  width: 50%;
}
.nav-tiles-container .nav-tile > * a:hover {
  box-shadow: -3px 3px white;
}
.nav-tiles-container .nav-tile.no-image > * {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}
.nav-tiles-container .nav-tile.no-image > * p {
  bottom: 0;
  position: relative;
  top: 0;
}
.nav-tiles-container .nav-tile:not(.flipped) > div > a {
  display: none;
}
.nav-tiles-container .nav-tile.flipped {
  transform: rotateY(180deg) scale(1.05);
}
.nav-tiles-container .nav-tile.flipped > * {
  background-image: none !important;
  transform: rotateY(180deg);
}
.nav-tiles-container .nav-tile.flipped > * p {
  display: none;
}
.nav-tiles-container .nav-tile.flipped > * div {
  display: block;
  padding-top: 3em;
}

.breadcrumbs {
  align-items: flex-start;
  display: flex;
  margin: auto;
  padding: 1em 1em 2em;
  width: 100%;
  flex-wrap: wrap;
}
.breadcrumbs > div > span {
  margin: 0 1em;
}

.ReactTable .rt-resizer {
  z-index: 0 !important;
}

.phone-number-form {
  display: flex;
}

.activity-outcome-content {
  padding-top: 20px;
}

.calendar-width,
.travel-history-container {
  width: 50%;
}

.custom-report-page > div {
  text-align: right;
}

.showChart {
  flex-wrap: wrap;
}

.assessmentForm_Title {
  width: 95%;
  align-items: center;
  display: flex;
  font-size: 20px;
  font-weight: bold;
}

.assessment-border {
  border: none;
}

.trips_Add {
  display: flex;
  position: relative;
  align-items: center;
  font-size: 1.5em;
  margin-bottom: 10px;
}
.trips_Add > a {
  margin-left: 0.5em;
}

.assessmentForm_Toggle_New_Past_Container {
  display: flex;
  margin-bottom: 20px;
}

.assessmentForm_Toggle_New_Past_Content {
  background-color: grey;
  display: flex;
  margin: auto;
  width: 60%;
  border: solid 1px grey;
  border-radius: 7px;
}

.assessmentForm_Toggle_Upcoming {
  cursor: pointer;
  margin: 1px;
  padding: 7px;
  width: 50%;
  border-radius: 5px;
}

.assessmentForm_Toggle_Past {
  cursor: pointer;
  margin: 1px;
  width: 50%;
  padding: 7px;
  border-radius: 5px;
}

.assessmentSelected {
  background-color: white;
  cursor: pointer;
  margin: 1px;
  width: 50%;
  padding: 7px;
  border-radius: 5px;
}

.showChart {
  display: inline;
}

.hideChart {
  display: none;
}

.applications {
  display: flex;
  width: 48%;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 2% 1%;
  align-items: center;
  box-shadow: 0px 0px 5px #bbb;
  padding: 10px;
  background-color: #fff;
}

.applications:hover {
  text-decoration: none;
  box-shadow: 0px 0px 10px #bbb;
}

.Trips {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.upcomingTripsImg {
  width: 30%;
}

.upcomingTripsImg > img {
  width: 100%;
  height: auto !important;
  border-radius: 5px;
  padding: 10px;
}

.upcomingTripsContent {
  width: 70%;
  color: black;
  text-align: left;
}

.upcomingTripsContent > div {
  padding: 5px 0px;
}

@media (max-width: 890px) {
  .text-align-right {
    margin-right: 20px;
  }
  .ii_country_page > div {
    float: none !important;
    width: 100% !important;
    height: 100% !important;
  }
  .DayPicker_focusRegion {
    max-width: 320px !important;
  }
  .DayPicker__withBorder {
    width: 320px !important;
    overflow: hidden;
  }
  .custom-report-page > div {
    text-align: center;
  }
  .immigration-flags > div {
    margin: 0 10px;
  }
  .flex-wrap {
    justify-content: center;
  }
  #assessment-form-upper-left > .row {
    flex-wrap: wrap !important;
  }
  #assessment-form-upper-left {
    margin-left: 15px !important;
  }
  .widget-main > #current-assessments-table {
    display: flex;
    overflow: scroll;
  }
  .widget-box:nth-child(2) > .widget-main {
    overflow: scroll;
  }
  .activity-outcome-content {
    display: flex;
    flex-wrap: wrap-reverse;
  }
  .assessment-border {
    border: none;
  }
}

@media (max-width: 687px) {
  .selected-passport {
    display: block;
    margin-left: 0;
  }
  .DateInput_fang {
    z-index: 0 !important;
  }
  .hover_grow {
    justify-content: center;
  }
  .applications {
    width: 100%;
  }
  .upcomingTripsImg > img {
    min-height: 25vw;
  }
  .dashboard h2 {
    font-size: 1.3rem;
  }
  .calendar-width,
  .travel-history-container {
    width: 100%;
  }
  .side-nav-closed {
    max-width: 30px;
  }
  .calendar-mobile {
    display: flex;
    flex-direction: column;
  }
  .text-align-right {
    margin-right: 35px;
  }
  .App > .d-flex > div:first-child {
    display: none;
  }
  .w-100 > div {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

@media (max-width: 575px) {
  .expando-main {
    padding: 22px !important;
  }
  .phone-number-form {
    display: block;
  }
  label {
    text-align: left !important;
  }
  .settingsForm {
    margin-left: 10px;
  }
  .col-sm-2 {
    max-width: unset;
  }
  .text-align-right {
    text-align: left;
  }
}

@media (max-width: 500px) {
  .side-nav-closed {
    display: none;
  }
  .hover_shrink {
    justify-content: center;
  }
  .App > div > .w-100 {
    position: absolute !important;
    padding-bottom: 40px;
  }
  .row > .text-left > h2 {
    text-align: center;
  }
  .h-100 > div {
    margin-right: 0px !important;
  }
  .h-100 > div > div > div > div > .col {
    justify-content: center !important;
    display: flex;
    padding-right: 0px !important;
  }
  .form-group {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .toggle.btn-xs {
    margin-left: 15px;
  }
  .mobileSettings {
    margin-right: 20px;
    margin-left: 20px;
  }
  .row > .text-right {
    text-align: left !important;
  }
  #activities {
    width: auto;
    margin-left: 0;
  }
  #assessment-form-upper-left {
    margin-left: 0;
    margin-right: 15px;
  }
  #assessment-form-upper-left > .row {
    justify-content: space-between;
  }
  #assessment-form-upper-left > .row input[type="checkbox"] {
    line-height: 1.5em;
    margin-right: 119px;
  }
  #assessment-form-upper-left > .row:first-of-type .picker-field {
    width: 200px;
  }
  .widget-main {
    overflow: visible !important;
  }
}

@media (max-width: 355px) {
  .App {
    position: fixed;
  }
  .App > div > .w-100 {
    padding-bottom: 100px;
  }
}

.immi_content {
  font-family: "Trebuchet MS";
  font-size: 0.9em;
  line-height: 1.2em;
  text-transform: none;
  font-weight: 400;
  font-style: normal;
  padding: 0 3%;
}

.immi_content h1 {
  font-weight: bold;
}

.immi_content h3 {
  font-size: 1.4em;
  font-weight: bold;
  line-height: 1.2em;
}

.immi_content li {
  margin-left: 15px;
}

/* immi-net page */
.ii_top {
  vertical-align: text-top;
  padding: 0 5px;
}

.ii_menu_td {
  background-color: #ffffff;
  width: 250px;
  height: 167px;
  float: left;
  padding: 10px;
}

.ii_menu_img {
  position: relative;
  top: -40px;
}

.ii_menu_box {
  position: relative;
  background-color: rgba(0, 107, 167, 0.9);
  color: #ffffff;
  height: 30px;
  width: 240px;
  text-align: center;
  padding: 10px 0 0 0;
  top: 120px;
  left: 5px;
  z-index: 1;
}

.ii_menu_text a {
  color: #ffffff;
}
.ii_menu_text a:hover {
  color: #d39e00;
  text-decoration: underline;
}

.gotcha {
  display: inline;
}

/* country-info page */
.ii_country_page {
  display: inline;
}

.ii_country_flag_div {
  width: 240px;
  float: left;
}

.ii_country_flag {
  width: 200px;
  height: 124px;
}

.ii_country_label {
  font-size: 1.5em;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.2em;
  padding: 5px 0 20px 0;
}

.taxassessment-body {
  border-style: solid;
  border-width: 2px;
  padding: 7px;
  border-radius: 5px;
  border-color: #ccc;
}

.taxassessment-summary {
  font-weight: bold;
}

.taxassessment-days-remaining {
  line-height: 200%;
}

.taxassessment-row {
  font-size: 80%;
  display: none;
}

.taxassessment-rule-content {
  border-style: solid;
  border-width: 2px;
  border-color: #a0a0a0;
  border-radius: 5px;
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  font-size: 120%;
  line-height: 120%;
}

.alert-info {
  border-style: solid;
  border-width: 2px;
  border-color: #a0a0a0;
  border-radius: 5px;
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  font-size: 120%;
  line-height: 120%;
}

.alert-info a {
  text-decoration: underline;
}

.alert-info a:hover {
  text-decoration: none;
}

.alert-Accept {
  border-style: solid;
  border-width: 2px;
  border-color: #a0a0a0;
  border-radius: 5px;
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  font-size: 120%;
  line-height: 120%;
}

.alert-warning {
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  font-size: 120%;
  line-height: 120%;
}

.taxassessment-title {
  font-weight: 600;
  border-bottom: 1px solid #888;
}

.passport {
  font-weight: 600;
  border-bottom: 1px solid #888;
}

.header.blue {
  font-weight: 500;
  border-bottom: 2px solid #aaa;
}

.video-container {
  position: relative;
  padding-bottom: 53.25%;
  height: 0;
  overflow: hidden;
}

.video-container,
.responsive-container object {
  max-width: 1280px;
  max-height: 720px;
}

.video-container object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.wiki-title {
  font-size: 30px;
  padding-left: 25px;
  font-weight: bolder;
  line-height: 40px;
}

.aag_note {
  font-weight: bolder;
  font-style: italic;
  font-size: 0.9em;
}

.aag_date {
  font-size: 0.8em;
}

.aag_network_image {
  float: left;
  width: 300px;
}

.aag_network_text {
  padding-top: 12px;
  margin-left: 310;
  width: 75%;
}

.country_header {
  height: 144px;
  padding: 0px 10px;
  position: relative;
  background-color: #4f81bd;
  background-image: url(//permiso-rails.therian.co/images/country/country_bknd.jpg);
}

.country_name {
  font-size: 40px;
  color: #ffffff;
  position: relative;
  top: 50px;
  left: 30px;
  width: 500px;
}

.country_header_img {
  position: absolute;
  top: 10px;
  right: 10px;
}

.page_content .center {
  margin-left: 40px;
  margin-right: auto;
  width: 98%;
  padding: 0px;
}

.page_content .button {
  -moz-box-shadow: inset 0px 1px 0px 0px #dcecfb;
  -webkit-box-shadow: inset 0px 1px 0px 0px #dcecfb;
  box-shadow: inset 0px 1px 0px 0px #dcecfb;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0.05, #bddbfa),
    color-stop(1, #80b5ea)
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#bddbfa', endColorstr='#80b5ea');
  background-color: #bddbfa;
  -webkit-border-top-left-radius: 9px;
  -moz-border-radius-topleft: 9px;
  border-top-left-radius: 9px;
  -webkit-border-top-right-radius: 9px;
  -moz-border-radius-topright: 9px;
  border-top-right-radius: 9px;
  -webkit-border-bottom-right-radius: 9px;
  -moz-border-radius-bottomright: 9px;
  border-bottom-right-radius: 9px;
  -webkit-border-bottom-left-radius: 9px;
  -moz-border-radius-bottomleft: 9px;
  border-bottom-left-radius: 9px;
  text-indent: 0;
  border: 1px solid #84bbf3;
  display: inline-block;
  color: #ffffff;
  font-family: Arial;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  height: 26px;
  line-height: 26px;
  width: 200px;
  text-decoration: none;
  text-align: center;
  text-shadow: 1px 1px 0px #528ecc;
}

.page_content .button:hover {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0.05, #80b5ea),
    color-stop(1, #bddbfa)
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80b5ea', endColorstr='#bddbfa');
  background-color: #80b5ea;
}

.page_content .button:active {
  position: relative;
  top: 1px;
}

.page_content .box_heading {
  border-style: solid;
  border-width: 5px;
  border-color: #4f81bd;
  border-radius: 8px;
  font-weight: bold;
  font-size: 1.2em;
  text-transform: uppercase;
  padding: 5px 5px 5px 5px;
  margin: 20px 40px 0px 0px;
}

.page_content .box_container {
  overflow: auto;
}

.page_content .box_left {
  float: left;
  width: 50%;
}

.page_content .box_right {
  margin-left: 50%;
}

.page_content .box_outline {
  width: 80%;
  padding: 0 10px;
  border-style: solid;
  border-width: 3px;
  border-color: #4f81bd;
  margin-top: 30px;
}

.page_content .box_title {
  font-family: "Calibri", "sans-serif";
  font-size: 14pt;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0px 10px;
  background: #4f81bd;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  position: relative;
  top: -15px;
}

.page_content .box_icon {
  float: right;
  margin-right: -60px;
}

.page_content .box_content {
  text-align: left;
  text-transform: none;
  margin-top: 5px;
  position: relative;
  padding-right: 30px;
}

.page_content .box_ordered_list {
  margin: 10px 0 0;
  overflow: hidden;
  text-transform: uppercase;
}

.page_content .box_ordered_list ol {
  color: #000000;
  padding: 0px;
  margin: 0 50px 0 50px;
}

.page_content .box_ordered_list li {
  padding: 0px;
  margin: 0;
  font-size: 2.5em;
  border-style: solid;
  border-width: 5px;
  border-color: white;
  background: #4f81bd;
}

.page_content .box_ordered_list p {
  color: white;
  text-transform: none;
  letter-spacing: normal;
  padding: 2px 10px;
  margin: 0px;
  font-size: 14px;
  font-family: "Calibri", "sans-serif";
  font-weight: normal;
  vertical-align: text-top;
  display: inline-block;
}

.page_content h1 {
  margin-top: 10pt;
  margin-right: 0in;
  margin-bottom: 0.8in;
  margin-left: 0in;
  margin-bottom: 0.0001pt;
  line-height: 115%;
  background: #4f81bd;
  border: none;
  padding: 2px 2px 2px 5px;
  font-size: 14pt;
  font-family: "Calibri", "sans-serif";
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.75pt;
}

.page_content h2 {
  margin-top: 10pt;
  margin-right: 0in;
  margin-bottom: 0.8in;
  margin-left: 0in;
  margin-bottom: 0.0001pt;
  line-height: 115%;
  background: #dbe5f1;
  border: none;
  padding: 2px 2px 2px 5px;
  font-size: 11pt;
  font-family: "Calibri", "sans-serif";
  text-transform: uppercase;
  letter-spacing: 0.75pt;
  font-weight: normal;
}

.page_content h3 {
  margin-top: 15pt;
  margin-right: 0in;
  margin-bottom: 0.8in;
  margin-left: 0in;
  margin-bottom: 0.0001pt;
  line-height: 115%;
  border: none;
  padding: 0in;
  font-size: 11pt;
  font-family: "Calibri", "sans-serif";
  color: #243f60;
  text-transform: uppercase;
  letter-spacing: 0.75pt;
  font-weight: normal;
}

.page_content h4 {
  margin-top: 15pt;
  margin-right: 0in;
  margin-bottom: 0.8in;
  margin-left: 0in;
  margin-bottom: 0.0001pt;
  line-height: 115%;
  border: none;
  padding: 0in;
  font-size: 11pt;
  font-family: "Calibri", "sans-serif";
  color: #365f91;
  text-transform: uppercase;
  letter-spacing: 0.5pt;
  font-weight: normal;
}

.country_summary_section > p {
  margin-bottom: 1rem !important;
}

.country_summary_section > p,
.country_summary_section > div {
  white-space: pre-wrap;
}
.country_summary_section > p *,
.country_summary_section > div * {
  margin: 0;
  white-space: normal;
}
.country_summary_section > p * + div,
.country_summary_section > p * + span,
.country_summary_section > div * + div,
.country_summary_section > div * + span {
  margin-top: -1rem;
}

#footer {
  align-self: center;
  bottom: 0;
  clear: both;
  flex-direction: column;
  font-size: 0.8em;
  height: 20px;
  justify-content: center;
  justify-items: center;
  left: 0;
  margin: 5px auto !important;
  width: 100%;
}

@media (max-width: 687px) {
  #footer {
    height: 30px;
    padding: 1em 0;
  }
}

.privacy {
  text-align: left;
  margin: 10px 20px;
}
.privacy h2 {
  font-size: 1.3rem;
  font-weight: bold;
  color: darkblue;
  margin-top: 16px;
}
.privacy h3 {
  display: inline;
  font-size: 1.1rem;
  font-weight: bold;
  margin-right: 8px;
}
.privacy div {
  margin-top: 12px;
}
.privacy div.address {
  line-height: 0.7rem;
}

.notification-bar {
  z-index: 100;
  background-color: #0b3c5d !important;
}
.notification-bar .notification-bar-action {
  color: #d9b310 !important;
}

#welcome-container {
  width: 100vw;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
  height: 50vh;
}
#welcome-container #welcome-logo-container {
  justify-content: space-around;
  flex-direction: column;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex: 1 1 auto;
  -moz-box-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: 0 auto 0 auto;
  width: 235px;
  height: 25vh;
}
#welcome-container #welcome-logo-container img {
  width: 100%;
}
#welcome-container #welcome-button-div {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
}
#welcome-container #welcome-button-div #welcome-button-label {
  margin: 0 auto 0 auto;
  width: 100px;
}
#welcome-container #welcome-button-div #welcome-button {
  margin: 10px auto 0 auto;
  width: 100px;
  background-color: #083d5d;
  color: white;
}

#legacy-assessment-container {
  text-align: left !important;
  font-weight: 400;
}
#legacy-assessment-container .passportoverallresult {
  font-weight: 200;
  margin-bottom: 10px;
}
#legacy-assessment-container .passport {
  font-weight: 500;
  font-size: 1.3rem;
  padding-bottom: 1px;
  margin-top: 30px !important;
  margin-bottom: 10px;
}
#legacy-assessment-container .cus-exclamation-octagon-fram,
#legacy-assessment-container .cus-accept {
  position: relative;
  font-style: normal;
  margin-right: 15px;
}
#legacy-assessment-container .cus-exclamation-octagon-fram:before {
  content: "\f06a";
  font-family: FontAwesome;
  color: red;
  position: absolute;
  top: 0;
}
#legacy-assessment-container .cus-accept:before {
  content: "\f058";
  font-family: FontAwesome;
  color: green;
  position: absolute;
  top: 0;
}
#legacy-assessment-container .alert-info {
  font-weight: 300;
  margin: 10px 0;
  border: 2px solid #979797;
  padding: 10px;
  color: #3b87aa !important;
}
#legacy-assessment-container ul {
  list-style: none;
  padding-left: 20px;
}

@media (min-width: 687px) {
  .hover_shrink {
    transition: transform 0.2s;
    /* Animation */
    transform: scale(1);
  }
  .hover_grow {
    transition: transform 0.2s;
    /* Animation */
    transform: scale(1.05);
  }
  .hover_grow .new_nav_tile {
    box-shadow: #bbb 2px 2px 2px;
    -webkit-box-shadow: #bbb 2px 2px 2px;
  }
}

.new_nav_tile {
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5%;
  cursor: pointer;
  background-color: white !important;
}
.new_nav_tile .title {
  color: black;
  font-size: 1.4em;
  padding: 0 1.2em;
}
.new_nav_tile .desc {
  color: black;
  font-size: 0.7em;
  padding: 0 0.5em;
}
.new_nav_tile a {
  border: solid 1px black;
  color: black !important;
  display: inline-block;
  padding: 4px;
  text-decoration: none !important;
  width: 50%;
}

.nav-list {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.nav-list > .row > div {
  align-items: flex-start;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 5px #bbb;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 5em;
  justify-content: center;
  margin: 0.5em 0;
  padding-left: 1em;
  text-align: left;
  width: 90%;
}

@media (max-width: 687px) {
  .nav-tiles {
    padding: 0 !important;
  }
  .nav-tiles > .row {
    display: block;
    margin: 0;
    padding: 0 !important;
    text-align: center;
  }
  .nav-tiles > .row > .col {
    box-shadow: 0 0 5px #bbb;
    display: inline-block;
    margin: 3px;
    margin-top: 3px !important;
    padding: 0px !important;
    vertical-align: top;
    width: 165px;
  }
  .nav-tiles > .row > .col .new_nav_tile {
    height: 165px;
    width: 165px;
  }
  .nav-tiles > .row > .col .new_nav_tile .title {
    font-size: 1em;
  }
}

.App {
  padding-bottom: 0 !important;
  background-color: #eee;
}
.App > div > .w-100 {
  height: calc(100vh - 56px);
  overflow: auto;
}
.App > div > .w-100 > .pr-5 {
  min-height: calc(100vh - 116px);
}

@media (max-width: 687px) {
  .h-100 {
    padding: 0 !important;
  }
  .h-100 > .row {
    margin-left: 0 !important;
  }
  .h-100 > .row > .col {
    padding: 0;
  }
}

.trip-confirmation-container,
.trip-confirmation-close {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  margin: 0;
  max-width: 100%;
  position: fixed;
  right: 0;
  top: 0;
}

.trip-confirmation-container {
  background: rgba(0, 0, 0, 0.4);
  z-index: 99;
}

.trip-confirmation-close {
  cursor: pointer;
}

.trip-confirmation-container .modal-content {
  max-width: 500px;
}
